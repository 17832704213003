import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppShellComponent} from './shell/app-shell/app-shell.component';
import {DashboardComponent} from './shell/dashboard/dashboard.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {MessagesModule} from "primeng/messages";
import {MessageService, SharedModule} from "primeng/api";
import {SelectButtonModule} from "primeng/selectbutton";
import {FormsModule} from "@angular/forms";
import {DocumentDetailsComponent} from './shell/document-details/document-details.component';
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {DocumentFieldsFormComponent} from './shell/document-details/document-fields-form/document-fields-form.component';
import {DocumentFieldComponent} from './shell/document-details/document-field/document-field.component';
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {MessageModule} from "primeng/message";
import {DropdownModule} from "primeng/dropdown";
import {CalendarModule} from "primeng/calendar";
import {InplaceModule} from "primeng/inplace";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {PaginatorModule} from "primeng/paginator";
import {
  CtrlEnterKeydownModule,
  CustomerModule,
  DocumentStatusModule,
  FieldProblemTypeModule,
  FieldProblemTypeSelectModule,
  FieldRequestValueModule,
  FieldValueStatusModule,
  ForceFocusDirectiveModule,
  IndexingFieldInputModule,
  NitroAuthModule,
  nitroAuthWithAngularOauth2Oidc,
  NitroConfigModule,
  nitroConfigWithKeyValues,
  NitroEnvBannerModule,
  NitroServiceModule,
  SimpleDatePipeModule,
  ThirdpartyIdentifiersInputModule,
  VatNumberInputModule,
  ButtonLinkModule,
} from "@fiscalteam/ngx-nitro-services";
import {ThirdpartyFormComponent} from "./shell/document-details/thirdparty-form/thirdparty-form.component";
import {CheckboxModule} from "primeng/checkbox";
import {FocusTrapModule} from "primeng/focustrap";
import {ToastModule} from "primeng/toast";
import {environment} from "../environments/environment";
import {DefaultIndexerFrontConfigs} from "./config/indexer-front-default-config";
import {DocumentWorklistNavigatorComponent} from "./document-worklist-navigator/document-worklist-navigator.component";
import {DocumentListRoute} from "./shell/document-list/document-list-route";
import {TableModule} from "primeng/table";
import {WorklistSelectComponent} from "./worklist-select/worklist-select.component";
import {IndexerErrorHandler} from "./service/indexer-error-handler";
import {CustomerListComponent} from "./shell/customer-list/customer-list.component";
import {ToggleButtonModule} from "primeng/togglebutton";
import {DialogModule} from "primeng/dialog";
import {InputTextareaModule} from "primeng/inputtextarea";
import {TagModule} from "primeng/tag";


@NgModule({
  declarations: [
    AppComponent,
    AppShellComponent,
    DashboardComponent,
    DocumentDetailsComponent,
    DocumentFieldsFormComponent,
    DocumentListRoute,
    CustomerListComponent,
    DocumentFieldComponent,
    ThirdpartyFormComponent,
    DocumentWorklistNavigatorComponent,
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NitroConfigModule.forRoot({
            domainApiClient: true,
            applicationName: "nitro-indexer-front",
            configValues: environment.configValues,
            asyncConfigValuesUri: environment.runtimeConfigPath,
            extraFeatures: [
                nitroConfigWithKeyValues(DefaultIndexerFrontConfigs, 0)
            ]
        }),
        NitroAuthModule.forRoot({
            implementation: nitroAuthWithAngularOauth2Oidc(),
            httpInterceptor: true,
        }),
        NitroServiceModule.forRoot({
            accounting: true,
            document: true,
            entity: true,
            indexing: true,
            security: true,
            metadata: true,
            utils: {
                errorHandler: true,
                httpErrorInterceptor: true,
                httpRequestedWithInterceptor: true,
                messagesService: true,
            }
        }),
        MessagesModule,
        SharedModule,
        SelectButtonModule,
        FormsModule,
        NgxExtendedPdfViewerModule,
        InputTextModule,
        ButtonModule,
        MessageModule,
        DropdownModule,
        CalendarModule,
        InplaceModule,
        OverlayPanelModule,
        PaginatorModule,
        IndexingFieldInputModule,
        CheckboxModule,
        VatNumberInputModule,
        ThirdpartyIdentifiersInputModule,
        FieldValueStatusModule,
        FocusTrapModule,
        CtrlEnterKeydownModule,
        FieldRequestValueModule,
        ToastModule,
        ForceFocusDirectiveModule,
        FieldProblemTypeSelectModule,
        FieldProblemTypeModule,
        NitroEnvBannerModule,
        TableModule,
        SimpleDatePipeModule,
        CustomerModule,
        DocumentStatusModule,
        WorklistSelectComponent, ButtonLinkModule, ToggleButtonModule, DialogModule, InputTextareaModule, TagModule
    ],

  providers: [
    MessageService,
    {
      provide: ErrorHandler,
      useClass: IndexerErrorHandler,
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
