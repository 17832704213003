import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {WsCustomerDocument, WsFieldIdentificationRequestStatus, WsFieldIdentificationValue, WsFieldIdentificationValueStatus, WsFieldProblemType, WsIndexingFieldType} from "@fiscalteam/nitro-domain-client";
import {BehaviorSubject, combineLatest, EMPTY, Observable, of, shareReplay, Subscription, switchMap, take, tap} from "rxjs";
import {NgForm} from "@angular/forms";
import {DocumentFieldComponent} from "../document-field/document-field.component";
import {debounceTime, filter, map} from "rxjs/operators";
import {DocumentTypeProblemService, ErrorService, IndexingFieldHandlersService, IndexingFieldModel} from "@fiscalteam/ngx-nitro-services";
import {IndexerDocumentFormService} from "./indexer-document-form.service";
import {IndexerDocumentFormFieldService} from "./indexer-document-form-field.service";
import {IndexerDocumentFormModel} from "./indexer-document-form-model";
import {IndexerDocumentFormFieldModel} from "./indexer-document-form-field-model";
import {DocumentIndexingStateService} from "./document-indexing-state-service";
import {IndexerDocumentFormSubmitService} from "./indexer-document-form-submit-service";
import {WorkListService} from "../../../service/work-list-service";
import {MessageService} from "primeng/api";


@Component({
  selector: 'idx-document-fields-form',
  templateUrl: './document-fields-form.component.html',
  styleUrls: ['./document-fields-form.component.scss'],
  providers: [IndexerDocumentFormService, IndexerDocumentFormFieldService, DocumentIndexingStateService, IndexerDocumentFormSubmitService, DocumentTypeProblemService, IndexingFieldHandlersService]
})
export class DocumentFieldsFormComponent implements OnInit, OnDestroy, AfterViewInit {

  loadingFormModel$ = new BehaviorSubject<boolean>(false);

  formModel?: IndexerDocumentFormModel;
  displayedFieldModel: IndexerDocumentFormFieldModel | undefined;
  displayedFieldEditable$: Observable<boolean> = of(false);
  displayedFieldLastToIndex$: Observable<boolean> = of(false);
  otherIndexedFields$: Observable<IndexerDocumentFormFieldModel[]> = of([]);
  submittingValues$: Observable<boolean> = of(false);
  notIndexableDocument$?: Observable<boolean>;

  // Expose WsFieldIdentificationRequestStatus enum constants to template
  WsFieldIdentificationRequestStatus = WsFieldIdentificationRequestStatus;
  WsFieldIdentificationValueStats = WsFieldIdentificationValueStatus;

  // Temporary fix: Don't allow other than well-typed and handled problems
  includeUntypedProblemOptions = true;

  popupVisible: boolean = false;
  problemComment: string = '';
  displaynotIndexablePopup: boolean = false;
  displayIncorectNamePopup: boolean = false;
  notIndexableDocComment: string = '';
  incorectNameComment: string = '';
  incorrectNameProblemDescriptions: Record<string, { description: string }> = {};
  document?: WsCustomerDocument;
  thirdpartyEntityId?: number;
  currentFieldModel?: IndexingFieldModel;
  currentFieldType?: string;
  // reload form
  private reloadSource$ = new BehaviorSubject<boolean>(false);
  @ViewChildren(DocumentFieldComponent) private fieldComponents!: QueryList<DocumentFieldComponent>;
  private subscription = new Subscription();

  constructor(private workListService: WorkListService,
              private formService: IndexerDocumentFormService,
              private fieldService: IndexerDocumentFormFieldService,
              private errorService: ErrorService,
              private submitService: IndexerDocumentFormSubmitService,
              private changeDetectorRef: ChangeDetectorRef,
              private documentTypeProblemService: DocumentTypeProblemService,
              private messageService: MessageService) {
    this.notIndexableDocument$ = of(false);

  }

  ngOnInit(): void {
    const newFormSubscription = this.workListService.getWorkListItem$().pipe(
      tap(i => this.loadingFormModel$.next(true)),
      switchMap(i => this.formService.createFormModel$(i)), tap(i => this.loadingFormModel$.next(false)),).subscribe({
      next: m => {
        this.resetIncorrectNameProblemDescriptions();
        this.formModel = m;
        this.formService.setFormModel(m);
        if (m != null) {
          // Note: We have nothing left to index for this doc.
          // Currently, we don't do anything, the user can switch to next doc.
          // When the worklist is finished, it is reinitialized
          // We could check here whether there is further doc in the worklist, and navigate to the next one
          // const fieldModelNeedingIndexing = m.fieldModels.find(m => this.fieldService.isFieldIndexingRequired(m));
          // const fieldModelNeedingSubmit = m.fieldModels.find(m => this.fieldService.isFieldSubmitRequired(m));
          this.document = m.document;
          this.notIndexableDocument$ = this.formService.isDocumentNotIndexable$(this.document).pipe(
            shareReplay({bufferSize: 1, refCount: true})
          );
        }
      }, error: e => this.errorService.handleError(e),
    });
    this.subscription.add(newFormSubscription);

    const fieldModelSubscription = this.fieldService.getDisplayedField$().subscribe(f => {
      // Force changedetection to notice a brand new field component, so that we can
      // refocus it
      this.displayedFieldModel = undefined;
      this.changeDetectorRef.detectChanges();
      this.displayedFieldModel = f;
    });
    this.subscription.add(fieldModelSubscription);

    const reloadedDisplauedField$ = combineLatest([this.fieldService.getDisplayedField$(), this.reloadSource$,]).pipe(map(r => r[0] as IndexerDocumentFormFieldModel), shareReplay({
      bufferSize: 1, refCount: true
    }));
    this.displayedFieldEditable$ = reloadedDisplauedField$.pipe(map(f => this.fieldService.isFieldEditable(f)), shareReplay({
      bufferSize: 1, refCount: true
    }),);
    this.displayedFieldLastToIndex$ = reloadedDisplauedField$.pipe(map(f => !this.formService.hasOtherFieldsToIndex(this.formModel, f)), shareReplay({
      bufferSize: 1, refCount: true
    }),);
    this.otherIndexedFields$ = reloadedDisplauedField$.pipe(map(f => this.formService.getOtherIndexedFields(this.formModel, f)), shareReplay({
      bufferSize: 1, refCount: true
    }),);
    this.submittingValues$ = this.submitService.submitting$;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.fieldService.displayField(undefined);
    this.formService.setFormModel(undefined);
  }

  ngAfterViewInit() {
    // Whenever we have a new documentfield input component, we focus it.
    const focusSubscription = this.displayedFieldEditable$.pipe(filter(r => r), switchMap(r => this.findFirstFieldInput$())).subscribe((c: DocumentFieldComponent | undefined) => {
      if (c) {
        c.focusInput();
      }
    });
    this.subscription.add(focusSubscription);
  }

  onSubmit(docForm: NgForm) {
    if (this.formModel) {
      const formModel = this.formModel;

      let submitTask$;
      if (this.displayedFieldModel) {
        const curFieldModel = this.displayedFieldModel;
        submitTask$ = this.waitFieldModelReadyToBeSubmitted$(curFieldModel).pipe(switchMap(ready => {
          const indexed = !this.fieldService.isFieldIndexingRequired(curFieldModel);
          if (ready && !indexed) {
            curFieldModel.pendingSubmitLocalChanges = Object.assign({}, curFieldModel.pendingSubmitLocalChanges, {
              valueStatus: WsFieldIdentificationValueStatus.Submitted
            } as Partial<WsFieldIdentificationValue>);
          } else if (!ready) {
            console.warn(`Skipping submitting form: field ${curFieldModel.fieldModel.field.code} not ready yet`);
            return EMPTY;
          }
          return this.submitService.submitForm$(formModel);
        }));
      } else {
        submitTask$ = this.submitService.submitForm$(formModel);
      }

      submitTask$.subscribe({
        next: (v) => {
          this.workListService.gotToNextItem();
        }, error: e => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Impossible d'envoyer les valeurs: ${e.message || e}`,
            life: 1000
          });
        }
      });
    }
  }

  onSubmitPopupProblemClick() {
    if (this.displayedFieldModel) {

      const fieldModel = this.displayedFieldModel.fieldModel;
      // Clear value
      fieldModel.typedValueUpdateSource$.next(undefined);
      // Set local values to submit
      this.displayedFieldModel.pendingSubmitLocalChanges = Object.assign({}, this.displayedFieldModel.pendingSubmitLocalChanges, {
        fieldProblemType: undefined,
        valueStatus: WsFieldIdentificationValueStatus.Problem,
        fieldProblemDetails: this.problemComment
      } as Partial<WsFieldIdentificationValue>);
      this.popupVisible = false;
      this.problemComment = '';
      this.switchToNextField();
    }
  }

  onSetFieldProblemClick(problemType: WsFieldProblemType | undefined) {

    if (problemType == undefined) {
      this.popupVisible = true;
    }
    if (this.displayedFieldModel && problemType != undefined) {
      const fieldModel = this.displayedFieldModel.fieldModel;
      // Clear value
      fieldModel.typedValueUpdateSource$.next(undefined);
      // Set local values to submit
      this.displayedFieldModel.pendingSubmitLocalChanges = Object.assign({}, this.displayedFieldModel.pendingSubmitLocalChanges, {
        fieldProblemType: problemType, valueStatus: WsFieldIdentificationValueStatus.Problem,
      } as Partial<WsFieldIdentificationValue>);
      this.switchToNextField();

    }
  }

  onCancelIncorrectNamePopup() {
    this.displayIncorectNamePopup = false;
  }

  onOpenIncorrectName(fieldModel: IndexingFieldModel, fieldType: string) {
    this.displayIncorectNamePopup = true;
    this.thirdpartyEntityId = Number(fieldModel.indexerValue$.getValue()?.identifiedValue);
    this.incorectNameComment = '';
    this.currentFieldModel = fieldModel;
    this.currentFieldType = fieldType; // Stored the fieldType for confirmation
  }

  onConfirmIncorrectName() {
    if (this.document?.id && this.thirdpartyEntityId) {
      this.documentTypeProblemService.createThirdpartyNameIncorectProblem$(this.document.id, this.thirdpartyEntityId, this.incorectNameComment).subscribe({
        next: (accountingDataProblem) => {
          if (this.currentFieldType) {
            this.incorrectNameProblemDescriptions[this.currentFieldType] = {
              description: accountingDataProblem.description!,
            };
          }
          this.displayIncorectNamePopup = false;
          this.messageService.add({severity: 'success', summary: 'Problem created!', life: 1000});
        }, error: (e) => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: `Error: ${e.message}`, life: 1000});
          this.displayIncorectNamePopup = false;
        },
      });
    }
  }

  isMatchingFieldType(actualType: WsIndexingFieldType, fieldType: string): boolean {
    return actualType === WsIndexingFieldType[fieldType as keyof typeof WsIndexingFieldType];
  }


  onConfirmNotIndexableDoc() {
    if (this.document?.id) {
      this.documentTypeProblemService.createNotIndexableDocProblem$(this.document.id, this.notIndexableDocComment).subscribe({
        next: (notIndexableProblem) => {
          this.messageService.add({
            severity: 'success', summary: 'Problem document not indexable created!', life: 1000
          });
          this.displaynotIndexablePopup = false;
          // Update notIndexableDocument$ observable after creation
          if (this.document) {
            this.notIndexableDocument$ = this.formService.isDocumentNotIndexable$(this.document).pipe(
              shareReplay({bufferSize: 1, refCount: true})
            );
          }
          this.workListService.removeCurrentItemAndNavigateNext();
        }, error: e => {
          this.messageService.add({
            severity: 'error', summary: 'Error', detail: `Error: ${e.message || e}`, life: 1000
          });
          this.displaynotIndexablePopup = false;
        }, complete: () => {

        }
      });
    }
  }

  onCancelNotIndexableDoc() {
    this.displaynotIndexablePopup = false;
  }

  onRestoreFieldClick(fieldModel: IndexerDocumentFormFieldModel) {
    this.fieldService.displayField(fieldModel);
  }

  switchToNextField() {
    if (this.displayedFieldModel) {
      // Wait for eventual value sync, then mark the field as ready to submit as long as there are not any error
      const fieldModelToSkip = this.displayedFieldModel;
      this.waitFieldModelReadyToBeSubmitted$(fieldModelToSkip).pipe(debounceTime(50)).subscribe(ready => {
        const indexed = !this.fieldService.isFieldIndexingRequired(fieldModelToSkip);
        if (ready && !indexed) {
          fieldModelToSkip.pendingSubmitLocalChanges = Object.assign({}, fieldModelToSkip.pendingSubmitLocalChanges, {
            valueStatus: WsFieldIdentificationValueStatus.Submitted
          } as Partial<WsFieldIdentificationValue>);
          this.reloadSource$.next(true);
        } else {
          // Skipped a field that is not ready to be submitted and will be shown back
        }
      });
    }
    // Switch to next field now
    this.fieldService.switchToNextField();
  }

  private resetIncorrectNameProblemDescriptions(): void {
    this.incorrectNameProblemDescriptions = {};
  }

  private waitFieldModelReadyToBeSubmitted$(fieldModel: IndexerDocumentFormFieldModel): Observable<boolean> {
    // Wait for eventual value sync, then mark the field as ready to submit as long as there are not any error
    if (fieldModel) {
      return fieldModel.fieldModel.indexerValueSyncing$.pipe(filter(s => !s), take(1), switchMap(_ => fieldModel.fieldModel.hasError$()), take(1), map((hasError) => {
        const filled = fieldModel.fieldModel.isIndexerFilledNow();
        const indexed = !this.fieldService.isFieldIndexingRequired(fieldModel);
        const valueRequired = fieldModel.fieldModel.fieldRequest.valueRequired;
        return indexed || (!hasError && (filled || !valueRequired));
      }));
    } else {
      return of(true);
    }
  }

  private findFirstFieldInput$(): Observable<DocumentFieldComponent | undefined> {
    return this.fieldComponents.changes.pipe(map((q: QueryList<DocumentFieldComponent>) => q.length > 0 ? q.first : undefined), filter(c => c != null), debounceTime(100),);
  }

}
